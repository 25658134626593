import React from "react"

import Head from "../components/Head"
import Template from "../components/Template"
import Text from "../components/Text"

export default () => {
  const data = require("../cms/mission.json")

  return (
    <>
      <Head title="Our Mission" />

      <Template
        h1="Our Mission"
      >

      <Text
        text={data.text}
      />

      </Template>
    </>
  )
}